/*
 * @Author: 张博洋
 * @Date: 2021-07-22 11:22:30
 * @LastEditTime: 2021-08-05 17:50:55
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/Button.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  Button
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[Button.name] = Button;
    return obj;
  })(),
}
