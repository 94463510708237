<!--
 * @Author: 张博洋
 * @Date: 2021-08-05 19:28:19
 * @LastEditTime: 2022-01-04 15:04:45
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/doctor/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="doctor-warp">
    <div class="item mt8"
         v-for="(item,index) in doctorList"
         :key="index">
      <div class="item-doc" @click="toDocdetail(item)">
        <div class="doc-img">
          <van-image width="100px"
                     height="118px"
                     fit="cover"
                     :src="item.avatar" />

        </div>
        <div class="doc-con ml16">
          <p><span class="name">{{item.doctorName}}</span> <span class="title">{{item.technicalLevelName}}</span></p>
          <p class="dep mt2">{{item.hospitalName}} {{item.departmentName}}</p>
          <p class="desc mt16">{{item.goodAt}}</p>
        </div>
      </div>
      <div class="item-service mt16"
           v-if="item.service.length !== 0">
        <div class="mb8"
             v-for="(citem,cindex) in item.service"
             :key="cindex" @click="toConsult(citem,item)">
          <div class="left">
            <img :src="citem.businessType === 1 ? require('@/static/img/icon-service1-1.png') : require('@/static/img/icon-service2-1.png')"
                 alt="">
            <div class="ml18">
              <p class="name">{{citem.title}}</p>
              <p class="service-detail">¥ {{citem.realPrice}}/{{citem.serviceTime}}{{citem.timeUnit === 1 ? '小时' : '分钟'}}</p>
            </div>
          </div>
          <van-button 
                      plain
                      round
                      :color="citem.businessType === 1 ? '#FF8200' : '#1AB393'">{{citem.businessType === 1 ? '立即咨询' : '立即问诊'}}</van-button>
        </div>
      </div>
      <div class="empty"
           v-else>
        <div>
          <img src="@/static/img/empty.png"
               alt="">
          <p class="mt4">暂无服务</p>
        </div>
      </div>

      <div class="doc-tag">
        医生主页
      </div>
    </div>
    <!-- <div v-if="doctorList.length === 0"
         class="empty">
      <img width="120"
           height="120"
           src="../../static/img/empty-doc.png"
           alt="">
      <p>暂无医生！</p>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import button from '@/mixin/Button.js'
export default {
  name: 'doctorList',
  mixins: [button],
  components: {},
  data() {
    return {
      query: {
        id: '1',
      },
      doctorList: [],
    }
  },
  created() {
    console.log('=======================================')
    this.$axios({
      url: 'doctor/getHotDoctorList',
      data: {},
      _self: this,
    }).then((res) => {
      // res.d.data || (res.d.data = []);
      this.doctorList = res.d.data
    })
    // console.log(this.$axios)
  },

  methods: {
    toConsult(citem, item) {
      console.log(citem, item)
      this.$router.push({
        name: 'consult.edit',
        query: {
          doctorProductId: citem.id,
          doctorAccountId: item.id,
          price: citem.realPrice,
          type: citem.businessType,
        },
      })
    },
    toDocdetail(item) {
      console.log(item.id)
      this.$router.push({
        name: 'doctor.detail',
        query: {
          doctorAccountId: item.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.doctor-warp {
  overflow-y: auto;
  padding-bottom: 16px;

  .item {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px 12px;
    margin: 0 8px;
    position: relative;
    .doc-tag {
      font-size: 11px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      width: 64px;
      line-height: 27px;
      background: #ff8200;
      border-radius: 14px 0px 0px 14px;
      text-align: center;
      position: absolute;
      top: 10px;
      right: 0;
    }
    .item-doc {
      display: flex;
      justify-content: space-around;
      padding: 17px 8px 17px;
      .doc-img {
        width: 100px;
        height: 118px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .doc-con {
        flex: 1;
        display: flex;
        flex-direction: column;
        p {
          .name {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
          }
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
        }
        p.dep {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
        p.desc {
          overflow: hidden;
          font-size: 13px;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: left;
        }
      }
    }
    .item-service {
      width: 100%;
      & > div {
        padding: 10px 16px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
          & > div {
            p.name {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            p.service-detail {
              font-size: 12px;
              font-weight: 500;
              color: #999999;
            }
          }
        }
        .van-button--round {
          width: 92px;
          height: 32px;
          font-weight: 500;
        }
      }
    }
    .empty {
      & > div {
        // margin: 0 12px;
        height: 74px;
        background: #f5f5f5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        img {
          width: 36px;
        }
      }
    }
  }
  // .empty {
  //   // width: 100%;
  //   // height: 100%;
  //   // display: flex;
  //   // align-items: center;
  //   // flex-direction: column;
  //   // justify-content: center;
  //   p {
  //     font-size: 14px;
  //     font-family: PingFangSC-Regular, PingFang SC;
  //     font-weight: 400;
  //     color: #999999;
  //   }
  // }
}
</style>
